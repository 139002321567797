import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/stores'
import { nameSpace, GetTableData, GetRaceDataLists } from '../service/service'

export interface TableInfo {
  name?: string
  gender?: 1 | 2 | undefined | null
  education?: string
  mobile?: number
  cardId?: number
  source?: string
  work?: string
  contactAddress?: string
  currentProfessionLevelName?: string
  currentProfessionTitle?: string
  applyProfessionName?: string
  applyProfessionLevelName?: string
  createTime?: number
  id?: number
  cardImage?: string
}

interface PreviewState {
  loading: boolean
  listLoading: boolean
  tableInfo: TableInfo
  raceDataLists: TableInfo[]
}

const initialState: PreviewState = {
  loading: false,
  listLoading: false,
  tableInfo: {},
  raceDataLists: [],
}

const PreviewTableSlice = createSlice({
  name: nameSpace,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setTableInfo(state, action: PayloadAction<{ tableInfo: TableInfo }>) {
      state.tableInfo = action.payload.tableInfo
      state.loading = false
    },
  },
  extraReducers: {
    [GetTableData.fulfilled.type]: (
      state,
      action: PayloadAction<{ tableInfo: TableInfo }>
    ) => {
      state.tableInfo = action.payload.tableInfo
      state.loading = false
    },
    [GetTableData.pending.type]: (state) => {
      state.loading = true
    },
    [GetTableData.rejected.type]: (state) => {
      state.loading = false
    },

    [GetRaceDataLists.fulfilled.type]: (
      state,
      action: PayloadAction<{ records: TableInfo[] }>
    ) => {
      state.raceDataLists = action.payload.records
      state.listLoading = false
    },
    [GetRaceDataLists.pending.type]: (state) => {
      state.listLoading = true
    },
    [GetRaceDataLists.rejected.type]: (state) => {
      state.listLoading = false
    },
  },
})

export const { setLoading, setTableInfo } = PreviewTableSlice.actions

export const selectLoading = (state: RootState): boolean =>
  state.personalTable.loading
export const selectTableInfo = (state: RootState): TableInfo =>
  state.personalTable.tableInfo as TableInfo

export const selectListLoading = (state: RootState): boolean =>
  state.personalTable.listLoading
export const selectRaceLists = (state: RootState): TableInfo[] =>
  state.personalTable.raceDataLists as TableInfo[]

export default PreviewTableSlice.reducer
