import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/stores'
import { nameSpace, SubmitLogin } from '../service/user.service'

interface UserState {
  loading: boolean
  isLogin: boolean
  userInfo: User | null
}

interface User {
  id: number
  gender: number
  headImage: string
  mobile: number
  name: string
}

const initialState: UserState = {
  loading: false,
  isLogin: false,
  userInfo: null,
}

const UserSlice = createSlice({
  name: nameSpace,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setUser(state, action: PayloadAction<{ user: User }>) {
      state.userInfo = action.payload.user
      state.isLogin = true
    },
    clearUser: (state) => {
      localStorage.removeItem('menus')
      state.isLogin = false
      state.userInfo = null
    },
  },
  extraReducers: {
    [SubmitLogin.fulfilled.type]: (
      state,
      action: PayloadAction<{ user: User }>
    ) => {
      state.userInfo = action.payload.user
      state.isLogin = true
      state.loading = false
    },
    [SubmitLogin.pending.type]: (state) => {
      state.loading = true
    },
    [SubmitLogin.rejected.type]: (state) => {
      state.loading = false
    },
  },
})

export const { setLoading, setUser, clearUser } = UserSlice.actions

export const selectLoading = (state: RootState): boolean => state.user.loading
export const selectIsLogin = (state: RootState): boolean => state.user.isLogin
export const selectUser = (state: RootState): User =>
  state.user.userInfo as User

export default UserSlice.reducer
