import { memo } from 'react'
import Cookies from 'js-cookie'
import { Redirect, useLocation } from 'react-router-dom'
import { Route as IRouter } from '@/routes/router'

interface CheckRouterProps {
  route: IRouter
  children?: React.ReactNode
}

const CheckRouter: React.FC<CheckRouterProps> = (props) => {
  const location = useLocation()

  const flatterRouter = (routers: IRouter[]) => {
    const result: IRouter[] = []
    for (let i = 0; i < routers.length; i++) {
      const router = routers[i]
      result.push({ ...router })
      if (router.childrens) {
        result.push(...flatterRouter(router.childrens || []))
      }
    }
    return result
  }

  if (!Cookies.get('token') && props.route.checkLogin) {
    return <Redirect to={{ pathname: '/login', state: location }} />
  }

  if (props.route.redirect) {
    return <Redirect to={props.route.redirect} />
  }
  return <>{props.children}</>
}

export default memo(CheckRouter)
