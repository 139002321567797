import Axios from '@/utils/axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { LoginParams } from '@/types/login.type'
import { setUser } from '../slice/user.slice'

export const nameSpace = 'user'

export const SubmitLogin = createAsyncThunk(
  `${nameSpace}/submitLogin`,
  async (userLogin: LoginParams, { dispatch }) => {
    const { data } = await Axios({
      url: '/user/login',
      method: 'POST',
      data: userLogin,
    })
    dispatch(setUser({ user: data?.data.user }))
    localStorage.setItem('menus', JSON.stringify(data?.data.menus))
    return data.data
  }
)
