import { configureStore } from '@reduxjs/toolkit'
import examSlice from '@/pages/exam/slice/exam.slice'
import userSlice from '@/pages/login/slice/user.slice'
import courseSlice from '@/pages/course/slice/course.slice'
import personalTableSlice from '@/pages/personalTable/slice/slice'

export const rootReducer = {
  user: userSlice,
  exam: examSlice,
  course: courseSlice,
  personalTable: personalTableSlice,
}

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development' ? true : false ?? false,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
