/**
 * 登录类型
 */
export enum loginTypeEnum {
  /** 账号/密码 */
  Account = 1,
  Phone,
}

export enum accountTypeEnum {
  login,
  register,
  code,
}

export enum PaperQuestionTypeEnum {
  Single = 1,
  Multiple = 2,
  Judgment = 3,
  Fill = 4,
  Question = 5,
  SingleBlock = 101,
  MultipleBlock = 102,
  JudgmentBlock = 103,
  FillBlock = 104,
  QuestionBlock = 105,
}
