/** 主页 */
export const MAIN_ROOT = '/'

/** API */
export const ApiPrefix = process.env.REACT_APP_API_URL

/** 响应成功 */
export const RES_SUCCESS_CODE = 0

// 分页数量
export const PAGE_SIZE = 10
