import _ from 'lodash'
import Cookies from 'js-cookie'
import { Suspense, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Layout, ConfigProvider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import zhCN from 'antd/lib/locale/zh_CN'
import SkillHeader from '@components/SkillHeader'
import SkillFooter from '@components/SkillFooter'
import CheckRouter from './components/CheckRouter'
import routers, { Route as IRouter } from '@/routes/router'
import { clearUser, selectUser, setUser } from './pages/login/slice/user.slice'

import './App.less'

const { Header, Content, Footer } = Layout

function App() {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (Cookies.get('user')) {
      if (_.isNull(user)) {
        dispatch(setUser({ user: JSON.parse(Cookies.get('user') ?? '{}') }))
      }
    } else {
      dispatch(clearUser())
    }
  }, [dispatch, user])

  const flatterRouter = (routers: IRouter[]) => {
    const result: IRouter[] = []
    for (let i = 0; i < routers.length; i++) {
      const router = routers[i]
      result.push({ ...router })
      if (router.childrens) {
        result.push(...flatterRouter(router.childrens || []))
      }
    }
    return result
  }

  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <Layout style={{ minHeight: '100%' }}>
          <Header className="header">
            <SkillHeader />
          </Header>
          <Content style={{ marginTop: '85px' }}>
            <Suspense fallback={<div>加载中...</div>}>
              <Switch>
                {flatterRouter(routers).map((route) => {
                  const { component: Component } = route
                  return (
                    <Route
                      key={route.path}
                      exact
                      path={route.path}
                      render={(props) => {
                        return Component ? (
                          <CheckRouter {...props} route={route}>
                            <Component {...props} />
                          </CheckRouter>
                        ) : (
                          <CheckRouter {...props} route={route} />
                        )
                      }}
                    />
                  )
                })}
              </Switch>
            </Suspense>
          </Content>
          <Footer className="footer">
            <SkillFooter />
          </Footer>
        </Layout>
      </Router>
    </ConfigProvider>
  )
}

export default App
