import { useInterval } from 'ahooks'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { Avatar, Button, Dropdown, Form, Input, Menu, Modal } from 'antd'
import {
  DownOutlined,
  EditOutlined,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons'
import logo from '@/assets/logo.jpg'
import erweima from '@/assets/erweima.png'
import pcLogin from '@/assets/pcLogin.png'
import { accountTypeEnum, loginTypeEnum } from '@/constants/enum'
import { SubmitLogin } from '@/pages/login/service/user.service'
import { selectIsLogin, selectUser } from '@/pages/login/slice/user.slice'
import Cookies from 'js-cookie'

import './index.less'
interface Menus {
  id?: number
  name: string
  url: string
  parentId?: number
  sons?: Menus[]
}

function SkillHeader() {
  const [loginVisible, setLoginVisible] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [timeOut, setTimeOut] = useState(0)
  const [interval, setInterval] = useState<number | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [accountType, setAccountType] = useState(accountTypeEnum.login)

  const history = useHistory()
  const dispatch = useDispatch()
  const isLogin = useSelector(selectIsLogin)
  const user = useSelector(selectUser)
  const menusStr = localStorage.getItem('menus') || '[]'
  const [menus, setMenus] = useState([])

  const [form] = Form.useForm()

  const handleClickLogout = () => {
    Cookies.remove('user')
    Cookies.remove('token')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    location.reload()
  }

  const handleViewRace = () => {
    history.push('/raceLists')
  }

  useEffect(() => {
    if (menusStr) {
      const allMenus = JSON.parse(menusStr)
      // 默认给首页tab
      setMenus(
        allMenus.length
          ? allMenus
          : [
              {
                name: '首页',
                url: '/home',
              },
            ]
      )
    }
  }, [menusStr])

  const menu = () => {
    return (
      <Menu>
        <Menu.Item
          style={{ width: '100px', textAlign: 'center' }}
          onClick={() => history.push('/user')}
        >
          个人中心
        </Menu.Item>
        <Menu.Item
          style={{ width: '100px', textAlign: 'center' }}
          onClick={() => handleViewRace()}
        >
          查看报名信息
        </Menu.Item>
        <Menu.Item
          style={{ width: '100px', textAlign: 'center' }}
          onClick={() => handleClickLogout()}
        >
          退出
        </Menu.Item>
      </Menu>
    )
  }

  useInterval(() => {
    setTimeOut(timeOut - 1)
  }, interval)

  useEffect(() => {
    if (timeOut <= 0) {
      setInterval(undefined)
      setBtnDisabled(false)
    }
  }, [timeOut])

  const handleCountdownCode = () => {
    setTimeOut(60)
    setInterval(1000)
    setBtnDisabled(true)
  }

  const handleSubmitLogin = async () => {
    const value = await form.validateFields()
    // setLoading(true)
    const data = dispatch(
      SubmitLogin({
        ...value,
        loginType: loginTypeEnum.Account,
      })
    )
    console.log(data)
    // setLoading(false)
  }

  return (
    <div className="skill-header">
      <Link to="/">
        <img alt="logo" className="header-logo" src={logo} />
      </Link>
      <ul className="header-navbar">
        {/* {menus.map((menu: Menus) => (
          <li key={menu.id}>
            {menu.url.includes('http') ? (
              <a href={menu.url} target="_blank" rel="noopener noreferrer">
                {menu.name}
              </a>
            ) : (
              <NavLink
                exact
                to={menu.url}
                activeClassName="header-navbar-active"
              >
                {menu.name}
              </NavLink>
            )}
          </li>
        ))} */}
        <li>
          <NavLink exact to="/home" activeClassName="header-navbar-active">
            首页
          </NavLink>
        </li>
        <li>
          <NavLink to="/profession" activeClassName="header-navbar-active">
            职业中心
          </NavLink>
        </li>
        <li>
          <NavLink to="/registration" activeClassName="header-navbar-active">
            在线报名
          </NavLink>
        </li>
        <li>
          <NavLink to="/exam?current=1" activeClassName="header-navbar-active">
            考试中心
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/download" activeClassName="header-navbar-active">
            下载APP
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/branding" activeClassName="header-navbar-active">
            品牌介绍
          </NavLink>
        </li> */}
        <li>
          <NavLink exact to="/faq" activeClassName="header-navbar-active">
            帮助中心
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/community" activeClassName="header-navbar-active">
            社区
          </NavLink>
        </li>
      </ul>
      <Input
        className="header-search"
        prefix={
          <img
            alt="search"
            style={{ width: '20px' }}
            src="https://aijiao100-static-1259136018.file.myqcloud.com/resources/7e43c414e7d1c2fa06d1ffaaf0558fb0.png"
          />
        }
        placeholder="请输入您要查找的内容"
      />
      {isLogin ? (
        <div style={{ marginLeft: '30px' }}>
          {user.headImage ? (
            <Avatar className="header-avatar" src={user.headImage} />
          ) : (
            <Avatar className="header-avatar" icon={<UserOutlined />} />
          )}
          <Dropdown overlay={menu()} placement="bottomCenter">
            <a>
              {user.name ?? '--'} <DownOutlined />
            </a>
          </Dropdown>
        </div>
      ) : (
        <Button
          size="large"
          shape="round"
          type="primary"
          style={{ marginLeft: '30px' }}
          onClick={() => setLoginVisible(true)}
        >
          登录 / 注册
        </Button>
      )}
      <Modal
        visible={loginVisible}
        centered
        footer={null}
        maskClosable={false}
        keyboard={false}
        title={
          accountType === accountTypeEnum.login
            ? '账号登录'
            : accountType === accountTypeEnum.register
            ? '快速注册'
            : '扫码登录'
        }
        destroyOnClose
        width={400}
        onCancel={() => {
          setLoginVisible(false)
          setAccountType(accountTypeEnum.login)
        }}
      >
        {accountType === accountTypeEnum.login ? (
          <Form form={form} preserve={false}>
            <Form.Item
              name="account"
              rules={[{ required: true, message: '请输入手机号/账号!' }]}
            >
              <Input
                prefix={<UserOutlined className="input-icon" />}
                placeholder="请输入手机号/账号"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="security"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input
                prefix={<LockOutlined className="input-icon" />}
                placeholder="请输入密码"
                type="password"
                size="large"
              />
            </Form.Item>
            <div className="new-forgot">忘记密码</div>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                shape="round"
                htmlType="submit"
                className="submit-btn"
                loading={loading}
                onClick={handleSubmitLogin}
                block
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        ) : accountType === accountTypeEnum.register ? (
          <Form form={form} preserve={false}>
            <Form.Item
              name="account"
              rules={[{ required: true, message: '请输入手机号/账号!' }]}
            >
              <Input
                prefix={<UserOutlined className="input-icon" />}
                placeholder="请输入手机号/账号"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="security"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input
                prefix={<LockOutlined className="input-icon" />}
                placeholder="请输入密码"
                type="password"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="checkCode"
              rules={[{ required: true, message: '请输入验证码!' }]}
            >
              <Input
                prefix={<EditOutlined className="input-icon" />}
                placeholder="请输入验证码"
                type="text"
                size="large"
                className="code-input"
                addonAfter={
                  <Button
                    className="code-btn"
                    type="primary"
                    onClick={handleCountdownCode}
                    disabled={btnDisabled}
                  >
                    {`${timeOut <= 0 ? '获取验证码' : `${timeOut}秒后重试`}`}
                  </Button>
                }
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                shape="round"
                htmlType="submit"
                className="submit-btn"
                loading={loading}
                block
              >
                注册
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div className="qrcode-wrapper">
            <div className="qrcode"></div>
            <div>打开择一技App</div>
            <div>在「账号」页右上角打开扫一扫</div>
          </div>
        )}
        <div
          className="new-register"
          onClick={() => {
            setAccountType(
              accountType === accountTypeEnum.login
                ? accountTypeEnum.register
                : accountTypeEnum.login
            )
            form.resetFields()
          }}
        >
          {accountType === accountTypeEnum.login
            ? '快速注册'
            : accountType === accountTypeEnum.register
            ? '返回账号登录'
            : null}
        </div>
        {accountType === accountTypeEnum.code ? (
          <img
            className="qrcode_login"
            src={pcLogin}
            alt="账号登录"
            onClick={() => setAccountType(accountTypeEnum.login)}
          />
        ) : (
          <img
            className="qrcode_login"
            src={erweima}
            alt="二维码登录"
            onClick={() => setAccountType(accountTypeEnum.code)}
          />
        )}
      </Modal>
    </div>
  )
}

export default SkillHeader
