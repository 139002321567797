import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from '@/utils/axios'
import { ExamParams } from '@/types/exam.type'
import { Paper, setSubmitExam } from '../slice/exam.slice'

export const nameSpace = 'exam'

export interface SubmitExam {
  completeFlag: boolean
  examId: number
  userExamId: number
  userExamPaperSubmits: userExam[]
}

interface userExam {
  paperId: number
  userExamQuestionSubmit: userQuestion[]
}

interface ExamReplyPage {
  current: number
  size: number
  examId: number
}

interface userQuestion {
  answer: string
  questionId: number
}

export const GetExamList = createAsyncThunk(
  `${nameSpace}/getExamList`,
  async (examParams: ExamParams) => {
    const { data } = await Axios({
      url: '/exam/page/self',
      method: 'GET',
      params: examParams,
    })
    return data.data
  }
)

export const GetExamInfo = createAsyncThunk(
  `${nameSpace}/getExamInfo`,
  async (examId: number, { dispatch }) => {
    const { data } = await Axios({
      url: '/user/exam/self/detail',
      method: 'GET',
      params: { examId },
    })
    data.data?.papers.map((paper: Paper) => dispatch(GetPaperDetail(paper.id)))
    return data.data
  }
)

export const GetPaperDetail = createAsyncThunk(
  `${nameSpace}/getPaperDetail`,
  async (paperId: number) => {
    const { data } = await Axios({
      url: '/paper/detail',
      method: 'GET',
      params: {
        id: paperId,
      },
    })
    return data.data
  }
)

export const SetExamStart = createAsyncThunk(
  `${nameSpace}/setExamStart`,
  async (examId: number) => {
    const { data } = await Axios({
      url: '/user/exam/start',
      method: 'POST',
      data: {
        examId,
      },
    })
    return data.data
  }
)

export const SubmitExam = createAsyncThunk(
  'exam/submitExam',
  async (
    { completeFlag, examId, userExamId, userExamPaperSubmits }: SubmitExam,
    { dispatch }
  ) => {
    const { data } = await Axios({
      url: '/user/exam/submit',
      method: 'POST',
      data: {
        completeFlag,
        examId,
        userExamId,
        userExamPaperSubmits,
      },
    })
    if (completeFlag) {
      dispatch(setSubmitExam(data.data))
    }
    return data.data
  }
)

export const GetExamAnswerDetail = createAsyncThunk(
  `${nameSpace}/getExamAnswerDetail`,
  async (userExamId: number) => {
    const { data } = await Axios({
      url: '/user/exam/self/answer/detail',
      method: 'GET',
      params: { userExamId },
    })
    return data.data
  }
)

export const GetExamReplyDetail = createAsyncThunk(
  `${nameSpace}/getExamReplyDetail`,
  async (userExamReplyId: number) => {
    const { data } = await Axios({
      url: '/user/exam/reply/self/detail',
      method: 'GET',
      params: { userExamReplyId },
    })
    return data.data
  }
)

export const GetExamReplyPage = createAsyncThunk(
  'exam/getExamReplyPage',
  async ({ current, examId, size }: ExamReplyPage) => {
    const { data } = await Axios({
      url: '/user/exam/reply/page/self',
      method: 'GET',
      params: {
        current,
        examId,
        size,
      },
    })
    return data.data
  }
)
