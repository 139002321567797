import { Link } from 'react-router-dom'
import android_qrcode from '@/assets/android_qrcode.png'
import './index.less'

function SkillFooter() {
  return (
    <div className="skill-footer">
      <div className="footer-qrcode">
        <img src={android_qrcode} alt="qrcode" className="qrcode" />
      </div>
      <div className="footer-desc">
        <ul className="footer-desc-links">
          <li>
            <Link to="#">公司简介</Link>
          </li>
          <li>
            <Link to="/documents/privacy">隐私条款</Link>
          </li>
          <li>
            <Link to="/about">联系我们</Link>
          </li>
          <li>
            <Link to="/about">商务合作</Link>
          </li>
          <li>
            <Link to="#">帮助中心</Link>
          </li>
          <li>
            <Link to="/exam?current=1">考试中心</Link>
          </li>
          <li>
            <Link to="/documents/service">用户服务协议</Link>
          </li>
        </ul>
        <ul className="footer-desc-content">
          <li>©2021杭州四盖儿科技有限公司</li>
          <li>电话：18268018114</li>
          <li>邮箱：sge_skill@163.com</li>
        </ul>
        <ul className="footer-desc-copyright">
          <li>地址：浙江省杭州市滨江区阡陌路459号聚光中心C1-102室</li>
          <li>
            <Link to="#">浙ICP备2021009875号-1</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SkillFooter
