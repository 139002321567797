import { PaperQuestionTypeEnum } from '@/constants/enum'
import { RootState } from '@/stores'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetExamAnswerDetail,
  GetExamInfo,
  GetExamList,
  GetExamReplyDetail,
  GetExamReplyPage,
  GetPaperDetail,
  nameSpace,
  SetExamStart,
  SubmitExam,
} from '../service/exam.service'

interface ExamState {
  loading: boolean
  examTotal: number
  exams: ExamPages[]
  exam: Exam | null
  papers: Paper[]
  userExam: UserExam | null
  userExamId: number
  paperQuestions: PaperQuestion[]
  questions: Question[]
  submitExam: boolean
  examReplyPage: UserExam[]
  examReplyTotal: number
  answerDetail: AnswerDetail[]
}

interface ExamPages {
  exam: Exam
  userExam: UserExam
}

export interface UserExam {
  id: number
  examId: number
  submitTime: number
  userId: number
  status: number
  score: number
  userName: string
  endTime: number
  createTime: number
  startTime: number
  userExamReplyLastId: number
}

export interface Exam {
  id: number
  name: string
  model: number
  description: string
  startTime: number
  spendTime: number
  modifyTime: number
  leftTime: number
  score: number
  relationPaperIds: string
  publishStatus: number
}

export interface Paper {
  id: number
  name: string
  score: number
}

interface PaperQuestion {
  paperId: number
  questionType: PaperQuestionTypeEnum
  questionContent: string
  score: number
}

export interface Question {
  id: number
  content: string
  title: string
  name: string
  type: number
  answer: string
  analysis: string
}

interface AnswerDetail {
  userPapers: UserPapers
  userQuestions: UserQuestions[]
}

interface UserPapers {
  id: number
  paperId: number
  score: number
  userExamId: number
}

interface UserQuestions {
  answer: string
  examId: number
  id: number
  paperId: number
  paperQuestionId: number
  questionId: number
  questionType: PaperQuestionTypeEnum
  score: number
  status: number
  userId: number
  userPaperId: number
}

const initialState: ExamState = {
  loading: false,
  examTotal: 0,
  exams: [],
  exam: null,
  papers: [],
  userExam: null,
  userExamId: 0,
  paperQuestions: [],
  questions: [],
  submitExam: false,
  examReplyPage: [],
  examReplyTotal: 0,
  answerDetail: [],
}

const ExamSlice = createSlice({
  name: nameSpace,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSubmitExam(state, action: PayloadAction<boolean>) {
      state.submitExam = action.payload
    },
  },
  extraReducers: {
    [GetExamList.fulfilled.type]: (
      state,
      action: PayloadAction<{ total: number; records: ExamPages[] }>
    ) => {
      state.loading = false
      state.examTotal = action.payload.total
      state.exams = action.payload.records
    },
    [GetExamList.pending.type]: (state) => {
      state.loading = true
    },
    [GetExamList.rejected.type]: (state) => {
      state.loading = false
    },
    [GetExamInfo.fulfilled.type]: (
      state,
      action: PayloadAction<{ exam: Exam; papers: Paper[]; userExam: UserExam }>
    ) => {
      state.loading = false
      state.exam = action.payload.exam
      state.papers = action.payload.papers
      state.userExam = action.payload.userExam
      state.userExamId = action.payload.userExam.id
    },
    [GetExamInfo.pending.type]: (state) => {
      state.loading = true
    },
    [GetExamInfo.rejected.type]: (state) => {
      state.loading = false
    },
    [GetPaperDetail.fulfilled.type]: (
      state,
      action: PayloadAction<{
        paperQuestionDetails: {
          paperQuestion: PaperQuestion
          questions: Question[]
        }[]
      }>
    ) => {
      state.loading = false
      state.paperQuestions = action.payload.paperQuestionDetails.map(
        (detail) => detail.paperQuestion
      )
      const detail = action.payload.paperQuestionDetails.map(
        (detail) => detail.questions
      )
      state.questions = ([] as Question[]).concat(...detail)
    },
    [GetPaperDetail.pending.type]: (state) => {
      state.loading = true
    },
    [GetPaperDetail.rejected.type]: (state) => {
      state.loading = false
    },
    [SetExamStart.fulfilled.type]: (
      state,
      action: PayloadAction<{ userExam: UserExam }>
    ) => {
      state.loading = false
      state.userExam = action.payload.userExam
      state.userExamId = action.payload.userExam.id
    },
    [SetExamStart.pending.type]: (state) => {
      state.loading = true
    },
    [SetExamStart.rejected.type]: (state) => {
      state.loading = false
    },
    [SubmitExam.fulfilled.type]: (state, action: PayloadAction<boolean>) => {
      state.loading = false
    },
    [SubmitExam.pending.type]: (state) => {
      state.loading = true
    },
    [SubmitExam.rejected.type]: (state) => {
      state.loading = false
    },
    [GetExamReplyPage.fulfilled.type]: (
      state,
      action: PayloadAction<{ total: number; records: UserExam[] }>
    ) => {
      state.examReplyPage = action.payload.records
      state.examReplyTotal = action.payload.total
      state.loading = false
    },
    [GetExamReplyPage.pending.type]: (state) => {
      state.loading = true
    },
    [GetExamReplyPage.rejected.type]: (state) => {
      state.loading = false
    },
    [GetExamAnswerDetail.fulfilled.type]: (
      state,
      action: PayloadAction<
        { userPapers: UserPapers; userQuestions: UserQuestions[] }[]
      >
    ) => {
      state.answerDetail = action.payload
      state.loading = false
    },
    [GetExamAnswerDetail.pending.type]: (state) => {
      state.loading = true
    },
    [GetExamAnswerDetail.rejected.type]: (state) => {
      state.loading = false
    },
    [GetExamReplyDetail.fulfilled.type]: (
      state,
      action: PayloadAction<
        { userPapers: UserPapers; userQuestions: UserQuestions[] }[]
      >
    ) => {
      state.answerDetail = action.payload
      // TODO: 暂时取第一个
      state.userExamId = action.payload[0].userPapers.userExamId
      state.loading = false
    },
    [GetExamReplyDetail.pending.type]: (state) => {
      state.loading = true
    },
    [GetExamReplyDetail.rejected.type]: (state) => {
      state.loading = false
    },
  },
})

export const { setLoading, setSubmitExam } = ExamSlice.actions

export const selectLoading = (state: RootState): boolean => state.exam.loading
export const selectExamTotal = (state: RootState): number =>
  state.exam.examTotal
export const selectExams = (state: RootState): ExamPages[] => state.exam.exams
export const selectExam = (state: RootState): Exam => state.exam.exam as Exam
export const selectUserExam = (state: RootState): UserExam =>
  state.exam.userExam as UserExam
export const selectUserExamId = (state: RootState): number =>
  state.exam.userExamId
export const selectPapers = (state: RootState): Paper[] => state.exam.papers
export const selectpaperQuestions = (state: RootState): PaperQuestion[] =>
  state.exam.paperQuestions
export const selectQuestions = (state: RootState): Question[] =>
  state.exam.questions
export const selectSubmitExam = (state: RootState): boolean =>
  state.exam.submitExam
export const selectExamReplyPage = (state: RootState): UserExam[] =>
  state.exam.examReplyPage
export const selectExamReplyTotal = (state: RootState): number =>
  state.exam.examReplyTotal
export const selectAnswerDetail = (state: RootState): AnswerDetail[] =>
  state.exam.answerDetail

export default ExamSlice.reducer
