import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from '@/utils/axios'

export const nameSpace = 'personalTable'

export const GetTableData = createAsyncThunk(
  `${nameSpace}/getTableData`,
  async (params: { id: number | string | null }) => {
    const { data } = await Axios({
      url: '/race/apply/self',
      method: 'GET',
      params,
    })
    return data.data
  }
)

export const GetRaceDataLists = createAsyncThunk(
  `${nameSpace}/getRaceDataLists`,
  async (params: {
    current?: number
    size?: number
    status?: number
    userId?: number
  }) => {
    const { data } = await Axios({
      url: '/race/apply/page/self',
      method: 'GET',
      params,
    })
    return data.data
  }
)
