import React, { lazy } from 'react'

export interface Route {
  path: string
  push?: boolean
  exact?: boolean
  redirect?: string
  childrens?: Route[]
  checkLogin: boolean
  component?: React.ElementType
}

const routers: Route[] = [
  {
    path: '/',
    redirect: '/home',
    exact: true,
    checkLogin: false,
    childrens: [
      {
        path: '/home',
        component: lazy(() => import('@pages/home')),
        checkLogin: false,
        exact: true,
      },
    ],
  },
  {
    path: '/login',
    component: lazy(() => import('@pages/login')),
    checkLogin: false,
    exact: true,
  },
  {
    path: '/download',
    component: lazy(() => import('@pages/download')),
    checkLogin: false,
    exact: true,
  },
  {
    path: '/course',
    component: lazy(() => import('@pages/course')),
    checkLogin: false,
    exact: true,
    childrens: [
      {
        path: '/course/detail/:id',
        component: lazy(() => import('@pages/course/courseDetail')),
        checkLogin: false,
        exact: true,
      },
    ],
  },
  {
    path: '/profession',
    component: lazy(() => import('@pages/profession')),
    checkLogin: false,
    exact: true,
    childrens: [
      {
        path: '/profession/detail/:id',
        component: lazy(() => import('@pages/profession/professionDetail')),
        checkLogin: false,
        exact: true,
      },
    ],
  },
  // {
  //   path: '/branding',
  //   component: lazy(() => import('@pages/index')),
  //   exact: true,
  // },
  {
    path: '/documents',
    redirect: '/documents/privacy',
    checkLogin: false,
    exact: true,
    childrens: [
      {
        path: '/documents/privacy',
        component: lazy(() => import('@pages/documents/privacy')),
        checkLogin: false,
        exact: true,
      },
      {
        path: '/documents/service',
        component: lazy(() => import('@pages/documents/service')),
        checkLogin: false,
        exact: true,
      },
    ],
  },
  {
    path: '/about',
    component: lazy(() => import('@pages/about')),
    checkLogin: false,
    exact: true,
  },
  {
    path: '/raceLists',
    component: lazy(() => import('@pages/personalTable/list')),
    checkLogin: false,
    exact: true,
  },
  {
    path: '/user',
    component: lazy(() => import('@pages/user')),
    checkLogin: false,
    exact: true,
  },
  {
    path: '/registration',
    component: lazy(() => import('@pages/registration')),
    checkLogin: false,
    exact: true,
  },
  {
    path: '/printPreview',
    component: lazy(() => import('@pages/personalTable')),
    checkLogin: false,
    exact: true,
  },
  {
    path: '/station',
    component: lazy(() => import('@pages/station')),
    checkLogin: false,
    exact: true,
  },
  {
    path: '/community',
    component: lazy(() => import('@pages/community')),
    checkLogin: false,
    exact: true,
  },
  {
    path: '/exam',
    component: lazy(() => import('@pages/exam')),
    checkLogin: true,
    exact: true,
    childrens: [
      {
        path: '/exam/info',
        component: lazy(() => import('@pages/exam/examInfo')),
        checkLogin: true,
        exact: true,
      },
      {
        path: '/exam/start',
        component: lazy(() => import('@pages/exam/examStart')),
        checkLogin: true,
        exact: true,
      },
      {
        path: '/exam/reply',
        component: lazy(() => import('@pages/exam/examStart')),
        checkLogin: true,
        exact: true,
      },
      {
        path: '/exam/result',
        component: lazy(() => import('@pages/exam/examResult')),
        checkLogin: true,
        exact: true,
      },
    ],
  },
  {
    path: '/404',
    component: lazy(() => import('@pages/notFound')),
    checkLogin: false,
    exact: true,
  },
  {
    path: '/*',
    redirect: '/404',
    checkLogin: false,
    exact: true,
  },
]

export default routers
